import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import Img, { FixedObject } from 'gatsby-image'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import TestimonialQuote from '/src/images/testimonial_quote.png'
import PositiveMin from '/src/images/positive-min.png'
import { FaStar } from "@react-icons/all-files/fa/FaStar";

const TestimonialSlider = ({ className }) => {
  const data = useStaticQuery ( graphql`
    query TestimonialSliderQuery {
      doug_r: file(name: { eq: "doug-r" }) {
        childImageSharp {
          fluid( maxWidth: 135, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      kyisha_s: file(name: { eq: "kyisha-s" }) {
        childImageSharp {
          fluid( maxWidth: 135, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      matt_s: file(name: { eq: "matt-s" }) {
        childImageSharp {
          fluid( maxWidth: 135, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      frank_b: file(name: { eq: "frank-b" }) {
        childImageSharp {
          fluid( maxWidth: 135, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      xavier_y: file(name: { eq: "xavier-y" }) {
        childImageSharp {
          fluid( maxWidth: 135, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      jean_p: file(name: { eq: "jean-p" }) {
        childImageSharp {
          fluid( maxWidth: 135, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      michelle_d: file(name: { eq: "michelle-d" }) {
        childImageSharp {
          fluid( maxWidth: 135, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      brian_b: file(name: { eq: "brian-b" }) {
        childImageSharp {
          fluid( maxWidth: 135, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      maxine_f: file(name: { eq: "maxine-f" }) {
        childImageSharp {
          fluid( maxWidth: 135, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      kevin_t: file(name: { eq: "kevin-t" }) {
        childImageSharp {
          fluid( maxWidth: 135, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      kandace_j: file(name: { eq: "kandace-j" }) {
        childImageSharp {
          fluid( maxWidth: 135, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      andy_w: file(name: { eq: "andy-w" }) {
        childImageSharp {
          fluid( maxWidth: 135, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      brit_c: file(name: { eq: "brit-c" }) {
        childImageSharp {
          fluid( maxWidth: 135, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      jonathan_a: file(name: { eq: "jonathan-a" }) {
        childImageSharp {
          fluid( maxWidth: 135, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      alex_m: file(name: { eq: "alex-m" }) {
        childImageSharp {
          fluid( maxWidth: 135, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      mary_v: file(name: { eq: "mary-v" }) {
        childImageSharp {
          fluid( maxWidth: 135, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      jeff_s: file(name: { eq: "jeff-s" }) {
        childImageSharp {
          fluid( maxWidth: 135, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      stacey_r: file(name: { eq: "stacey-r" }) {
        childImageSharp {
          fluid( maxWidth: 135, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      lisa_a: file(name: { eq: "lisa-a" }) {
        childImageSharp {
          fluid( maxWidth: 135, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      kristee_a: file(name: { eq: "kristee-a" }) {
        childImageSharp {
          fluid( maxWidth: 135, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      adrien_b: file(name: { eq: "adrien-b" }) {
        childImageSharp {
          fluid( maxWidth: 135, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      },
    }
  `
  )

  // Set ImageData.
  const doug_r = data.doug_r?.childImageSharp?.fluid
  const kyisha_s = data.kyisha_s?.childImageSharp?.fluid
  const matt_s = data.matt_s?.childImageSharp?.fluid
  const frank_b = data.frank_b?.childImageSharp?.fluid
  const xavier_y = data.xavier_y?.childImageSharp?.fluid
  const jean_p = data.jean_p?.childImageSharp?.fluid
  const michelle_d = data.michelle_d?.childImageSharp?.fluid
  const brian_b = data.brian_b?.childImageSharp?.fluid
  const maxine_f = data.maxine_f?.childImageSharp?.fluid
  const kevin_t = data.kevin_t?.childImageSharp?.fluid
  const kandace_j = data.kandace_j?.childImageSharp?.fluid
  const andy_w = data.andy_w?.childImageSharp?.fluid
  const brit_c = data.brit_c?.childImageSharp?.fluid
  const jonathan_a = data.jonathan_a?.childImageSharp?.fluid
  const alex_m = data.alex_m?.childImageSharp?.fluid
  const mary_v = data.mary_v?.childImageSharp?.fluid
  const jeff_s = data.jeff_s?.childImageSharp?.fluid
  const stacey_r = data.stacey_r?.childImageSharp?.fluid
  const lisa_a = data.lisa_a?.childImageSharp?.fluid
  const kristee_a = data.kristee_a?.childImageSharp?.fluid
  const adrien_b = data.adrien_b?.childImageSharp?.fluid

  var settings = {
    speed: 1000,
    autoplaySpeed: 3000,
    fadeIn: false,
    autoplay: true,
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true
          
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true
        }
      }
    ]
  };

  return (
    <div className="col-lg-12 testimonial-container">
      <Slider {...settings}>
        <div className="testimonial-slide">
          <div className="testimonial-image-container">
            <Img fluid={doug_r} />
            <p className="text-center text-white testimonial-author"><em>Doug R.</em></p>
          </div>
          <div className="testimonial-desc-container">
            <span><img src={TestimonialQuote} height="19" width="27"/> <img src={PositiveMin} height="16" width="16" /> Convenient scheduling, friendly and helpful staff. I actually enjoyed my visit to DGC. I feel I received a <button className="read-more"><span className="text-black">...</span>read more</button><span className="read-less-desc" id="doug-readmore">thorough evaluation and great results from the procedure. I walked out feeling like I made some new friends and confidence in my treatment. <span>read less</span></span></span>
          </div>
        </div>
        <div className="testimonial-slide">
          <div className="testimonial-image-container">
            <Img fluid={kyisha_s} />
            <p className="text-center text-white testimonial-author"><em>Kyisha S.</em></p>
          </div>
          <div className="testimonial-desc-container">
            <span><img src={TestimonialQuote} height="19" width="27"/> <img src={PositiveMin} height="16" width="16" /> the dentists is such a lovely sight to look at his face makes you forget the pain.</span>
          </div>
        </div>
        <div className="testimonial-slide">
          <div className="testimonial-image-container">
            <Img fluid={matt_s} />
            <p className="text-center text-white testimonial-author"><em>Matt S.</em></p>
          </div>
          <div className="testimonial-desc-container">
            <span><img src={TestimonialQuote} height="19" width="27"/> <img src={PositiveMin} height="16" width="16" /> Dr. Vogt is an excellent dentist who does quality work. Highly recommend!</span>
          </div>
        </div>
        <div className="testimonial-slide">
          <div className="testimonial-image-container">
            <Img fluid={frank_b} />
            <p className="text-center text-white testimonial-author"><em>Frank B.</em></p>
          </div>
          <div className="testimonial-desc-container">
            <span><img src={TestimonialQuote} height="19" width="27"/> <img src={PositiveMin} height="16" width="16" /> Beautiful office and fabulous staff and Dr ?</span>
          </div>
        </div>
        <div className="testimonial-slide">
          <div className="testimonial-image-container">
            <Img fluid={xavier_y} />
            <p className="text-center text-white testimonial-author"><em>Xavier Y.</em></p>
          </div>
          <div className="testimonial-desc-container">
            <span><img src={TestimonialQuote} height="19" width="27"/> <FaStar size="16px" className="star svg-icon-middle"/><FaStar size="16px" className="star svg-icon-middle"/><FaStar size="16px" className="star svg-icon-middle"/><FaStar size="16px" className="star svg-icon-middle"/><FaStar size="16px" className="star svg-icon-middle"/> I had an awesome experience this morning! Going to the dentist is definitely not my favorite thing to do...<button className="read-more"><span className="text-black">...</span>read more</button><span className="read-less-desc"> but Dr. Matt Vogt and the entire team made this an enjoyable, positive, and comfortable experience for me! It's definitely a judgement free zone and he takes his time getting to know you as well as explaining every step of the appointment! Very pleased and looking forward to continuing my dental treatments at this office for as long as I live in the area! <span>read less</span></span></span>
          </div>
        </div>
        <div className="testimonial-slide">
          <div className="testimonial-image-container">
            <Img fluid={jean_p} />
            <p className="text-center text-white testimonial-author"><em>Jean P.</em></p>
          </div>
          <div className="testimonial-desc-container">
            <span><img src={TestimonialQuote} height="19" width="27"/> <img src={PositiveMin} height="16" width="16" /> They are a great team!!! Dr. Vogt and the whole team are amazing, caring and professional!. They explained the procedure <button className="read-more"><span className="text-black">...</span>read more</button> <span className="read-less-desc">to the detail, very reassuring. They have an amazing up to date equipments that makes it convenient to understand what's needs to be done. Their office is warm, welcoming and very clean. Overall it was a great experience and 100% highly recommended 🙂 <span>read less</span></span></span>
          </div>
        </div>
        <div className="testimonial-slide">
          <div className="testimonial-image-container">
            <Img fluid={michelle_d} />
            <p className="text-center text-white testimonial-author"><em>Michelle D.</em></p>
          </div>
          <div className="testimonial-desc-container">
            <span><img src={TestimonialQuote} height="19" width="27"/> <img src={PositiveMin} height="16" width="16" /> This was my 6 year old daughter's first visit to the dentist! She loved it and said it was a<button className="read-more"><span className="text-black">...</span>read more</button> <span className="read-less-desc">lot of fun! Very comfortable and clean environment. I am scared of dentist because of a bad experience. Since my daughter had such a comfortable experience the whole family is scheduled! <span>read less</span></span></span>
          </div>
        </div>
        <div className="testimonial-slide">
          <div className="testimonial-image-container">
            <Img fluid={brian_b} />
            <p className="text-center text-white testimonial-author"><em>Brian B.</em></p>
          </div>
          <div className="testimonial-desc-container">
            <span><img src={TestimonialQuote} height="19" width="27"/> <FaStar size="16px" className="star svg-icon-middle"/><FaStar size="16px" className="star svg-icon-middle"/><FaStar size="16px" className="star svg-icon-middle"/><FaStar size="16px" className="star svg-icon-middle"/><FaStar size="16px" className="star svg-icon-middle"/> Normally I would say I hate going to the dentist and have a lot of anxiety when I go.<button className="read-more"><span className="text-black">...</span>read more</button> <span className="read-less-desc">Lot of bad experiences. Dr Matt was different. I didn't experience any anxiety and he took the time to explain things. You won't feel like just a mouth full of teeth and $$, he takes the time to get to know his patients. <span>read less</span></span></span>
          </div>
        </div>
        <div className="testimonial-slide">
          <div className="testimonial-image-container">
            <Img fluid={maxine_f} />
            <p className="text-center text-white testimonial-author"><em>Maxine F.</em></p>
          </div>
          <div className="testimonial-desc-container">
            <span><img src={TestimonialQuote} height="19" width="27"/> <FaStar size="16px" className="star svg-icon-middle"/><FaStar size="16px" className="star svg-icon-middle"/><FaStar size="16px" className="star svg-icon-middle"/><FaStar size="16px" className="star svg-icon-middle"/><FaStar size="16px" className="star svg-icon-middle"/>  I would highly recommend Dr. Vogt! The staff is welcoming and makes you feel comfortable from start to finish. The<button className="read-more"><span className="text-black">...</span>read more</button> <span className="read-less-desc">facility is new, clean, and fresh. I am happy to have found a great dentist in my new community. <span>read less</span></span></span>
          </div>
        </div>
        <div className="testimonial-slide">
          <div className="testimonial-image-container">
            <Img fluid={kevin_t} />
            <p className="text-center text-white testimonial-author"><em>Kevin-and T.</em></p>
          </div>
          <div className="testimonial-desc-container">
            <span><img src={TestimonialQuote} height="19" width="27"/> <img src={PositiveMin} height="16" width="16" /> Great location, friendly staff and excellent care!</span>
          </div>
        </div>
        <div className="testimonial-slide">
          <div className="testimonial-image-container">
            <Img fluid={kandace_j} />
            <p className="text-center text-white testimonial-author"><em>Kandace J.</em></p>
          </div>
          <div className="testimonial-desc-container">
            <span><img src={TestimonialQuote} height="19" width="27"/> <img src={PositiveMin} height="16" width="16" />  Dr. V and his staff were super welcoming and extremely gentle. I was able to be seen within hours of<button className="read-more"><span className="text-black">...</span>read more</button> <span className="read-less-desc">calling for an appointment. The environment is clean and fresh. I would definitely recommend Dr. V and his team. <span>read less</span></span></span>
          </div>
        </div>
        <div className="testimonial-slide">
          <div className="testimonial-image-container">
            <Img fluid={andy_w} />
            <p className="text-center text-white testimonial-author"><em>Andy W.</em></p>
          </div>
          <div className="testimonial-desc-container">
            <span><img src={TestimonialQuote} height="19" width="27"/> <img src={PositiveMin} height="16" width="16" /> Excellent care & service from Dr. Vogt & the staff. This was my first visit to the office & I<button className="read-more"><span className="text-black">...</span>read more</button> <span className="read-less-desc">am very happy with how everything went. <span>read less</span></span></span>
          </div>
        </div>
        <div className="testimonial-slide">
          <div className="testimonial-image-container">
            <Img fluid={brit_c} />
            <p className="text-center text-white testimonial-author"><em>Brit C.</em></p>
          </div>
          <div className="testimonial-desc-container">
            <span><img src={TestimonialQuote} height="19" width="27"/> <img src={PositiveMin} height="16" width="16" /> Great location, friendly staff and excellent care!</span>
          </div>
        </div>
      </Slider>
    </div>
  )
}

export default TestimonialSlider
