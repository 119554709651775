import React from 'react'
import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'
import { IndexQueryQuery, PostByPathQuery } from '../../types/graphql-types'
import TestimonialSlider from 'components/sliders/testimonial-sliders'
import ButtonBlack from 'components/button/button-black'
import Post from '../templates/post/post'
import Meta from 'components/meta/meta'
import Layout from 'components/layout/layout'
import styled from 'styled-components'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import BackgroundImage from 'gatsby-background-image'
import PositiveMin from '/src/images/positive-min.png'
import OGImage from 'images/the-dentists-at-gateway-crossing.jpg'
import Logo from 'images/logo.png'
import BtnOutlined from 'components/button/outlined-button'


interface Props {
  data: IndexQueryQuery
  location: Location
}

const TestimonialsPage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const posts = data.remark.posts
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const office_section2_background = data.office_section2_background?.childImageSharp?.fluid
  const linda_e = data.linda_e?.childImageSharp?.fluid
  const adrien_b = data.adrien_b?.childImageSharp?.fluid
  const stevie_c = data.stevie_c?.childImageSharp?.fluid
  const no_profile_pic = data.no_profile_pic?.childImageSharp?.fluid
  const jean_p = data.jean_p?.childImageSharp?.fluid
  const doug_r = data.doug_r?.childImageSharp?.fluid
  const tony_s = data.tony_s?.childImageSharp?.fluid
  const brittany_h = data.brittany_h?.childImageSharp?.fluid
  const matthew_r = data.matthew_r?.childImageSharp?.fluid

  return (
    <Layout location={location}>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>Testimonials  - The Dentists At Gateway Crossing</title>
      <meta name="description" content="OUR PATIENTS LOVE US!" />
      <meta name="robots" content="index, follow, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content="Testimonials - The Dentists At Gateway Crossing" />
      <meta property="og:description" content="OUR PATIENTS LOVE US!" />
      <meta property="og:url" content="https://thedentistsatgc.com/testimonials/" />
      <meta property="og:site_name" content="The Dentists At Gateway Crossing" />
      <meta property="article:publisher" content="https://www.facebook.com/thedentistsatgc/" />
      <meta property="og:updated_time" content="2021-02-05T05:10:25+00:00" />
      <meta property="og:image" content={OGImage} />
      <meta property="og:image:secure_url" content={OGImage} />
      <meta property="og:image:width" content="1080" />
      <meta property="og:image:height" content="1080" />
      <meta property="og:image:alt" content="McCordsville Dentist" />
      <meta property="og:image:type" content="image/jpeg" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Testimonials - The Dentists At Gateway Crossing" />
      <meta name="twitter:description" content="OUR PATIENTS LOVE US!" />
      <meta name="twitter:image" content={OGImage} />
      <meta name="google-site-verification" content="khYQGQ-T8YWkXa0QyKr6e2kNlFB3l0ZiFRA89VzVbaM" />
      <meta name="google-site-verification" content="Oll6PjpMRzNx1KbgKilxS6xPddvSbF2lDOa2rd7VkxA" />
      <meta name="msapplication-TileImage" content={Logo} />
      <script async src='https://tag.simpli.fi/sifitag/195cd770-bf42-013a-5143-0cc47a8ffaac'></script>
    </Helmet>
      <BackgroundImage
        Tag="section"
        className="hero-section-bg"
        fluid={hero_background}
        backgroundColor={`#e9f4f8`}
        alt="The Dentists at Gateway Crossing Office"
      >
        <div className ="col-lg-12" id ="color-overlay">
        </div>
        <div id="hero-section" className="text-center">
          <Container>
            <div className="col-lg-12 text-center">
              <p className="hero-text">
                OUR PATIENTS LOVE US!
              </p>
              <h1 className="text-light">
                PATIENT TESTIMONIALS
              </h1>
            </div>
            <div className="col-lg-12">
              <p className="hero-text text-light m-t-20">
                We know you want to see the reviews first! Browse the page below to see our reviews. Afterwards, It’s easy to schedule your appointment with us.
              </p>
              <p className="hero-text text-light">
                Just click “schedule now” as soon as you’re ready.
              </p>
            </div>
            <div className="col-lg-12 text-center">
              <a href="#testimonials-section-3"><button className="btn-rounded"> Learn More </button></a>
              <a href="https://flexbook.me/tdagc/website" target="_blank"><button className="btn-rounded"> Schedule Now </button></a>
            </div>
          </Container>
        </div>
      </BackgroundImage>
      <section id="testimonials-section-1" className="page-section">
        <Container>
          <div className="col-md-12 text-center">
            <p className="hero-text">
              Dental Patient Reviews
            </p>
            <h2>Reviews Are Important!</h2>
            <p>
              We know that you want to make the best decision possible when choosing a dentist for you and your family. Making the right choice from the beginning will save you a ton of time and headaches. That is why we like to put our reviews out in the open for everyone to see! When you choose us as your McCordsville dentist, your satisfaction is guaranteed.
            </p>
          </div>
        </Container>
      </section>
      <section id="testimonials-section-2" className="page-section">
        <Container>
          <div className="col-md-12 text-center">
            <h2>Check Out Our Happy Customers Below</h2>
            <p>
              If you mouse over the reviews below, they will stop moving and you can read them. Use the arrows on either side to scroll through the rest when you’re ready.
            </p>
          </div>
          <TestimonialSlider />
        </Container>
      </section>
      <BackgroundImage
        Tag="section"
        className="office-section2-bg"
        fluid={office_section2_background}
        backgroundColor={`#e9f4f8`}
        alt="The Dentists at Gateway Crossing"
      >
        <div className ="col-lg-12" id ="color-overlay">  </div>
        <section id="office-section-2" className="page-section">
          <Container>
            <div className="col-lg-6">
              <p className="hero-text">
                LOVE YOUR SMILE!
              </p>
              <h3 className="text-light">
                Don't miss your chance to live life with the smile you've always wanted!
              </h3>
              <p className="hero-text text-light">
                Stop delaying your health! Your teeth can’t afford another day without proper care. Call TODAY!
              </p>
              <BtnOutlined url="tel:3176439434" class="btn-rounded" label="(317) 643-9434" />
            </div>
          </Container>
        </section>
      </BackgroundImage>
      <section id="testimonials-section-3" className="page-section">
          <Container>
            <div className="col-lg-12">
              <p className="hero-text">
                MORE DENTAL REVIEWS
              </p>
              <h3 className="text-black text-left">
                Even More Reviews
              </h3>
            </div>
            <div className="col-lg-12">
              <div className="testimonial-simple">
                <div className="testimonial-simple-content">
                  <p><span><img src={PositiveMin} height="16" width="16" /> I felt very comfortable and taken care of! </span></p>
                </div>
                <div className="col-lg-1">
                  <Img fluid={linda_e} />
                </div>
                <div className="col-lg-2 testimonial-name">
                  <p><span> LINDA E. </span></p>
                </div>
                <div className="testimonial-simple-content">
                  <p><span><img src={PositiveMin} height="16" width="16" /> Wow! I just had the best experience at The Dentists at Gateway Crossing. Dr. Matt Vogt and his staff were extremely friendly. We laughed and had fun while quickly getting my dental exam taken care of. They have the best X-ray and imaging process too. Everything they show you is displayed on a flatscreen television in high definition right where you can see it. We spoke about fixing the alignment of my teeth as well as taking care of some cavities. Overall the office was very clean, warm, and welcoming. I felt really good about choosing this office and very confident in Dr. Vogt being my dentist when he said “I just love teeth.” It really shows that he cares a lot about what he is doing and he really wants to help everyone have a healthier, happier smile. I highly recommend these guys with all of your dental work and your Invisalign braces, if you need them. Plus they have a great plan for self employed business owners. Ask about it!</span></p>
                </div>
                <div className="col-lg-1">
                  <Img fluid={adrien_b} />
                </div>
                <div className="col-lg-2 testimonial-name">
                  <p><span> ADRIEN B. </span></p>
                </div>
                <div className="testimonial-simple-content">
                  <p><span><img src={PositiveMin} height="16" width="16" /> Wonderful dentists!!!!!!!’n </span></p>
                </div>
                <div className="col-lg-1">
                  <Img fluid={stevie_c} />
                </div>
                <div className="col-lg-2 testimonial-name">
                  <p><span> STEVIE C. </span></p>
                </div>
                <div className="testimonial-simple-content">
                  <p><span><img src={PositiveMin} height="16" width="16" /> I am sold!
                      <br/>
                      <br/>
                      I had gone to the same dentist for my entire life. When he moved away, I put off finding a new dentist for years. I finally decided enough was enough. I was very nervous to go to a new office and not looking forward to the judgement for not going to the dentist for 3 years.
                      <br/>
                      <br/>
                      Let’s just start with my greeting from Joan the second that I walked in the door! She is so sweet and made me feel at home! I waited less than a minute for Dr. Vogt to come out and introduce himself to me. He took the time to ask about my life and get to know me before we jumped into everything else! When I told him that I hadn’t been to the dentist in 3 years, he didn’t judge for a second. This made me feel so much better! He took the time to explain everything to me before he started doing the work, he continued to ask if I was doing alright and if I had any questions. Before he started any of the work, Joan came in and sat with me to explain pricing and options. Throughout all the work being done Dr. Vogt and Talya were awesome! They made me feel so comfortable! They even let me watch my favorite TV show the whole time! I highly recommend this awesome team! </span></p>
                </div>
                <div className="col-lg-1">
                  <Img fluid={no_profile_pic} />
                </div>
                <div className="col-lg-2 testimonial-name">
                  <p><span> DANIELLE D. </span></p>
                </div>
                 <div className="testimonial-simple-content">
                  <p><span><img src={PositiveMin} height="16" width="16" /> They are a great team!!! Dr. Vogt and the whole team are amazing, caring and professional!. They explained the procedure to the detail, very reassuring. They have an amazing up to date equipments that makes it convenient to understand what's needs to be done. Their office is warm, welcoming and very clean. Overall it was a great experience and 100% highly recommended 🙂 </span></p>
                </div>
                <div className="col-lg-1">
                  <Img fluid={jean_p} />
                </div>
                <div className="col-lg-2 testimonial-name">
                  <p><span> JEAN P. </span></p>
                </div>
                <div className="testimonial-simple-content">
                  <p><span><img src={PositiveMin} height="16" width="16" /> My husband and I just switched dentists to Dr. Vogt and we’re both really happy with our experiences so far. Everyone is professional and all so kind. I was actually having a rough day before my first appointment, but everyone’s upbeat personalities helped raise my spirits a little bit! I especially liked that I could message them on Facebook and get answers in a timely manner. It can be such a pain to reach doctors or get through to someone when you don’t have much time at work to stay on the phone. Dr. Vogt and his staff also make it a point to know his patients on a personal level. I don’t think my previous dentist would recognize me outside of my appointments. My husband had an appointment earlier today, and Dr. Vogt just called and left a voicemail making sure he was feeling better. Little things like that really show me that my doctors care. I would highly recommend The Dentists at Gateway Crossing if you’re new to the area or just looking for a new dentist! </span></p>
                </div>
                <div className="col-lg-1">
                  <Img fluid={no_profile_pic} />
                </div>
                <div className="col-lg-2 testimonial-name">
                  <p><span> SAMANTHA P. </span></p>
                </div>
                <div className="testimonial-simple-content">
                  <p><span><img src={PositiveMin} height="16" width="16" /> Convenient scheduling, friendly and helpful staff. I actually enjoyed my visit to DGC. I feel I received a thorough evaluation and great results from the procedure. I walked out feeling like I made some new friends and confidence in my treatment. </span></p>
                </div>
                <div className="col-lg-1">
                  <Img fluid={doug_r} />
                </div>
                <div className="col-lg-2 testimonial-name">
                  <p><span> DOUG R. </span></p>
                </div>
                <div className="testimonial-simple-content">
                  <p><span><img src={PositiveMin} height="16" width="16" /> Finally!!! Finally a dentist that will sit and discuss everything with you. Cares about what you need and want. A dentist that will sit and discuss options, and not just blow smoke, and try to make the biggest dollar off you. A dentist that truly cares about you as an individual, and not another source of money.
                      <br/>
                      <br/>
                      Dr. Vogt truly cares about his patients, and makes sure you are beyond happy. Soon as you walk in the door the staff makes you feel as if you are a long lost friend. It is truly hard to find a place with good customer service, and they go beyond that.
                      <br/>
                      <br/>
                      For someone who hasn’t been to the dentist for over 6yrs, I can truly say, I have no problem coming here, and we’ll return here with no fear.</span></p>
                </div>
                <div className="col-lg-1">
                  <Img fluid={linda_e} />
                </div>
                <div className="col-lg-2 testimonial-name">
                  <p><span> TONY S. </span></p>
                </div>
                <div className="testimonial-simple-content">
                  <p><span><img src={PositiveMin} height="16" width="16" /> Great employees that made my 10 month old happy and calm during his first dentist appointment. </span></p>
                </div>
                <div className="col-lg-1">
                  <Img fluid={brittany_h} />
                </div>
                <div className="col-lg-2 testimonial-name">
                  <p><span> BRITTANY H. </span></p>
                </div>
                 <div className="testimonial-simple-content">
                  <p><span><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i> Matt and his staff are amazing! Booking appointments online is easy, the office is clean and well decorated, and it was the most gentle teeth cleaning I've ever had.
 </span></p>
                </div>
                <div className="col-lg-1">
                  <Img fluid={matthew_r} />
                </div>
                <div className="col-lg-2 testimonial-name">
                  <p><span> MATTHEW R. </span></p>
                </div>
                <br/>
                <br/>
                <br/>
                <h3 className="text-black text-left">
                  Do You Take My Insurance?
                </h3>
                <p>
                  Every patient’s situation is different. We would love to discuss payment options with you. Please call our office at <a href="tel:3176438667">317-643-8667</a>.
                </p>
              </div>
            </div>
          </Container>
        </section>
    </Layout>
  )
}

export default TestimonialsPage

export const query = graphql`
  query TestimonialsPageQuery {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter
      }
    },
    remark: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      posts: edges {
        post: node {
          html
          frontmatter {
            layout
            title
            path
            category
            author
            tags
            description
            date(formatString: "YYYY/MM/DD")
            image {
              childImageSharp {
                fluid(maxHeight: 362) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    },
    linda_e: file(name: { eq: "linda-e" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    adrien_b: file(name: { eq: "adrien-b" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    stevie_c: file(name: { eq: "stevie-c" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    no_profile_pic: file(name: { eq: "no-profile-pic" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    jean_p: file(name: { eq: "jean-p" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    doug_r: file(name: { eq: "doug-r" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    tony_s: file(name: { eq: "tony-s" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    brittany_h: file(name: { eq: "brittany-h" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    matthew_r: file(name: { eq: "matthew-r" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hero_background: file(name: { eq: "about-us-hero" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    office_section2_background: file(name: { eq: "placeholder" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
